module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-theme-ui@0.3.4_qz43rm2jocu3ebvoksvn6sdmem/node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-mdx@1.5.0_qvmiwj4bll2b7za4qga6lrfofi/node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"defaultLayouts":{"content":"/home/runner/work/personal-site/personal-site/src/components/Article.js","default":"/home/runner/work/personal-site/personal-site/src/components/Layout.js"},"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-autolink-headers","options":{"className":"header-link","isIconAfterHeader":true}},{"resolve":"gatsby-remark-table-of-contents","options":{"exclude":["Intermission","Table of Contents"],"toHeading":3}},"gatsby-remark-copy-linked-files","gatsby-remark-smartypants",{"resolve":"gatsby-remark-prismjs","options":{"showLineNumbers":true}}],"extensions":[".mdx"],"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-typography@2.7.0_nn6exq35bsvwrusymte22v6ypu/node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"./src/styles/typography","omitGoogleFont":true},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@2.7.0_gatsby@2.27.3/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"George Song's Site","short_name":"gsong.dev","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"./src/images/gs-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"7dac84e604769a2b765823229eec619b"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-google-analytics@2.6.0_ffteqj3qecowsmkf5eyepihek4/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-22541040-3","head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0},
    }]
