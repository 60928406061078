// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---content-articles-datalist-autosuggest-index-mdx": () => import("./../../../content/articles/datalist-autosuggest/index.mdx" /* webpackChunkName: "component---content-articles-datalist-autosuggest-index-mdx" */),
  "component---content-articles-reducer-patterns-part-1-mdx": () => import("./../../../content/articles/reducer-patterns/part-1.mdx" /* webpackChunkName: "component---content-articles-reducer-patterns-part-1-mdx" */),
  "component---content-articles-reducer-patterns-part-2-mdx": () => import("./../../../content/articles/reducer-patterns/part-2.mdx" /* webpackChunkName: "component---content-articles-reducer-patterns-part-2-mdx" */),
  "component---content-articles-reducer-patterns-part-3-mdx": () => import("./../../../content/articles/reducer-patterns/part-3.mdx" /* webpackChunkName: "component---content-articles-reducer-patterns-part-3-mdx" */),
  "component---content-articles-remove-unwanted-properties-mdx": () => import("./../../../content/articles/remove-unwanted-properties.mdx" /* webpackChunkName: "component---content-articles-remove-unwanted-properties-mdx" */),
  "component---content-articles-to-locale-date-string-index-mdx": () => import("./../../../content/articles/to-locale-date-string/index.mdx" /* webpackChunkName: "component---content-articles-to-locale-date-string-index-mdx" */),
  "component---content-articles-workplace-justice-ally-mdx": () => import("./../../../content/articles/workplace-justice-ally.mdx" /* webpackChunkName: "component---content-articles-workplace-justice-ally-mdx" */),
  "component---src-pages-404-mdx": () => import("./../../../src/pages/404.mdx" /* webpackChunkName: "component---src-pages-404-mdx" */),
  "component---src-pages-drafts-colophon-mdx": () => import("./../../../src/pages/drafts/colophon.mdx" /* webpackChunkName: "component---src-pages-drafts-colophon-mdx" */),
  "component---src-pages-index-mdx": () => import("./../../../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */)
}

